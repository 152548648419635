<template>

    <b-card>

      <b-row>

  


          <b-col
                      cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                    v-if="loading"
                    align="center"
                  >
  
                  <b-row>
  
                    <b-col cols="12" sm="4" md="4" lg="4" xl="4">
  
                      <b-skeleton width="100%"  height="10px"></b-skeleton>
  
  
                    </b-col>

  
                    <b-col cols="12" sm="8" md="8" lg="8" xl="8">  </b-col>



                    <b-col cols="12" sm="4" md="4" lg="4" xl="4">
                        
                        <b-skeleton width="100%"  height="40px"></b-skeleton>


                      </b-col>

                      <b-col cols="12" sm="4" md="4" lg="4" xl="4">
                        
                        <b-skeleton width="100%"  height="40px"></b-skeleton>


                      </b-col>

                      <b-col cols="12" sm="4" md="4" lg="4" xl="4">
                        
                        <b-skeleton width="100%"  height="40px"></b-skeleton>


                      </b-col>

                      <b-col cols="12" sm="4" md="4" lg="4" xl="4">
  
                        <b-skeleton width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                        <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>


                      </b-col>

                      <b-col cols="12" sm="8" md="8" lg="8" xl="8">
                          
                          <b-skeleton width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                          <b-skeleton width="100%"  height="40px" style="margin-bottom:0px"></b-skeleton>


                        </b-col>

                        <b-col cols="12" sm="3" md="3" lg="3" xl="3">

                        </b-col>
  
                 
  
                        <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 15px;">
  
                        <b-skeleton width="100%"  height="10px"></b-skeleton>
  
                        <table width="100%">
                                    <tr>
                                      <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                                    </tr>
                                  </table>
  
                        </b-col>

                        <b-col cols="12" sm="3" md="3" lg="3" xl="3"></b-col>
  
                        <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  > </b-col>
  
  
                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  >
  
                                  <b-skeleton width="100%"  height="40px"></b-skeleton>
                                
                                </b-col>
  
  
                                <b-col
                                cols="4"
                                    sm="4"
                                    md="4"
                                    lg="4"
                                    xl="4"
                                  > </b-col>
  
  
                    
  
  
                  </b-row>
  
                  </b-col>
  
            


                      <b-col
                      v-else-if="hasIdentificacion"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                        style="padding: 0;"
                      >



                      <b-row>

                        <b-col
                        cols="12"
                  
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                         style="margin-bottom:10px"
                        align="center"
                       
                      >
                        <img
  
                          alt="Todo ok"
                          src="/img/ok.svg"
                        >

                        <p style="text-align: center; font-size: 14px;">
                          Ya tienes registrado un documento de identidad
                        </p>

                        </b-col>



                        <b-col
                        cols="6"
                  style="margin-bottom:10px"
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"

                        align="center"
                       
                      >

                      <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                          <p style="margin:0px; text-align: center;">{{ identificacionLabel }}</p>

                            <p style="margin:0px;font-size:12px; font-weight: bold;text-align: center;">Nro. documento</p>

                     
                        </div>
                      </b-alert>


               

                      </b-col>

                      <b-col
                      cols="6"
                  style="margin-bottom:10px"
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"
                          
                                align="center"
                              
                              >

                              <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                          <p style="margin:0px; text-align: center;">{{ typeDocumentLabel }}</p>

                            <p style="margin:0px;font-size:12px; font-weight: bold;text-align: center;">Tipo de documento</p>

                     
                        </div>
                      </b-alert>

                         
                              </b-col>

                      <b-col
                   cols="12"
                  style="margin-bottom:10px"
                                    sm="12"
                                    md="12"
                                    lg="6"
                                    xl="6"
                   
                        align="center"
                      
                      >

                      <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                          <p style="margin:0px; text-align: center;">{{ displayNameLabel }}</p>

                            <p style="margin:0px;font-size:12px; font-weight: bold;text-align: center;">Razón social / nombres y apellidos</p>

                     
                        </div>
                      </b-alert>


                


                      </b-col>

                   





                      </b-row>


                    

                      </b-col>

                      
                      
      <b-form
      v-else
        autocomplete="off"
        @submit.prevent="onSubmit"
      >
      <b-row>

        <b-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-bottom:10px"
        >
        <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                          <p style="margin:0px; text-align: center;">Una vez que registres el documento de identidad del negocio, no podrá volver a modificar estos datos</p>

                         
                     
                        </div>
                      </b-alert>


        </b-col>
      


        <b-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        >

          <p class="textLabel">Seleccione un documento de identidad:</p>
            </b-col>

            <b-col
            cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="4"
                                
                              >

                            <div class="inputGroup">



                            <input id="DNI" name="radio" @change="onChange($event)" :checked="isDni" :disabled="isActiveProfile"  value="DNI" type="radio"/>
                            <label for="DNI"> 

                              Número de cédula (DNI)</label>
                            </div>

                            </b-col>

                            <b-col
                              cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="4"
                                
                              >

                            <div class="inputGroup">



                            <input id="RUC" name="radio" @change="onChange($event)" :checked="isRuc" :disabled="isActiveProfile"  value="RUC" type="radio"/>
                            <label for="RUC"> 

                               Número de RUC </label>
                            </div>

                            </b-col>

                            <b-col
                              cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                xl="4"
                                
                              >

                            <div class="inputGroup">



                            <input id="Fiscal" name="radio" @change="onChange($event)" :checked="isFiscal"  :disabled="isActiveProfile" value="Fiscal" type="radio"/>
                            <label for="Fiscal"> 

                             Nro. identificación fiscal</label>
                            </div>

                            </b-col>



            <b-col
            v-if="isDni"
            sm="12"
            md="12"
            lg="4"
            xl="4"
            style="margin-bottom: 10px;margin-top:10px"
          >

            <p class="textLabel">
              Ingrese su número de cédula (*):
            </p>

            <b-form-input
              v-model="identificacion"
              placeholder="Ingrese su número de cédula"
              required
              maxlength="10"
              @keypress="NumbersOnly"
              type="tel"
              :readonly="isActiveProfile"
           
    
            />


          </b-col>

          <b-col
            v-else-if="isRuc"
            sm="12"
            md="12"
            lg="4"
            xl="4"
            style="margin-bottom: 10px;margin-top:10px"
          >

            <p class="textLabel">
              Ingrese su número de RUC (*):
            </p>

            <b-form-input
              v-model="identificacion"
              placeholder="Ingrese su número de RUC"
              required
              maxlength="13"
              @keypress="NumbersOnly"
              type="tel"
              :readonly="isActiveProfile"
            
    
            />


          </b-col>

          <b-col
            v-else-if="isFiscal"
            sm="12"
            md="12"
            lg="4"
            xl="4"
            style="margin-bottom: 10px;margin-top:10px"
          >

            <p class="textLabel">
              Ingrese su identificación fiscal (*):
            </p>

            <b-form-input
              v-model="identificacion"
              placeholder="Ingrese su identificación fiscal"
              required
              @keypress="NumbersOnly"
              type="tel"
              :readonly="isActiveProfile"
            />


          </b-col>

          <b-col
          sm="12"
            md="12"
            lg="8"
            xl="8"
            style="margin-bottom: 10px; margin-top:10px"
          >

            <p v-if="isRuc" class="textLabel">
              Razón social:
            </p>


            <p v-else class="textLabel">
              Nombres y apellidos del usuario:
            </p>

      

              <b-form-input
              v-model="displayName"
              placeholder="Razón social/nombres y apellidos"
              required
              type="text"
              :readonly="isActiveProfileS"
              @keypress="isLetter"
            />

          </b-col>




          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >

            <p
              class="textLabel"
              style="text-align: center;margin-bottom: 10px;"
            >
              Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

            <PincodeInput
              v-model="pin"
              placeholder="-"
              :length="6"
              :secure="true"
              required
              :autofocus="false"
            />

          </b-col>


         

          
          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            align="center"
          >

            <b-button
              id="btn_general"
              ref="btn_general"
              type="submit"
              variant="primary"
              :disabled="isActiveProfile"
            >

              Actualizar datos

            </b-button>

          </b-col>
  
      
      </b-row>

      </b-form>



          </b-row>

                  
 </b-card>
      
         
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BFormInput, BForm, BSkeleton, BAlert
      } from 'bootstrap-vue'
      
      import PincodeInput from 'vue-pincode-input'
      
      export default {
        components: {
          BForm,
          BFormInput,
          BButton,
          BRow,
          BCol,
          BCard,
          BSkeleton,
          PincodeInput,
          BAlert
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId','tokenAuth', 'idLocal'],
        data() {
      
      
          return {
            documento:"",
            pin:"",
            checked: true,
            isDni:true,
            identificacionLabel:"",
            displayNameLabel:"",
            typeDocumentLabel:"",
            isRuc:false,
            isFiscal:false,
            isActiveProfile: false,
            dniRespaldo: '',
            displayName:"",
            loading:true,
            readonlyDisplayName:false,
                  identificacion:"",
                  typeDocument :"DNI",
            hasIdentificacion:false,
              rucRespaldo:"",
              numeroEstablecimiento:"",
              nombreLocal:"",
               direccionCompleta:"",
       
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          this.loadGeneral();

      
        },
        methods: {

          
      onSubmit(event) {
        event.preventDefault();

  

  

          const userId_json = {nombreLocal:this.nombreLocal,direccionCompleta:this.direccionCompleta, userId: this.userId,pin: this.pin,displayName: this.displayName, typeDocument: this.typeDocument, identificacion: this.identificacion, idLocal: this.idLocal }
              const user_string = JSON.stringify(userId_json)
        
              
        
              this.isActiveProfile=true;
         
              const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
        
              document.getElementById('btn_general').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" /> Actualizando'
        
              this.$https.post('/locals/updateIdentificacion/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                this.isActiveProfile=false;

                document.getElementById('btn_general').innerHTML = 'Actualizar datos'
        
                if (response.data.code == 200) {
            
                  this.$toast.success(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  });

                  this.$eventBus.$emit('reiniciarGeneralNegocio');
                  this.$eventBus.$emit('reiniciarPerfilCompletado2', false)
                  this.$eventBus.$emit('reiniciarTeamNegocio', this.typeDocument)
                  this.$eventBus.$emit('reiniciarPerfilCompletado')
        

                } else {
                  this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
        
                  if (response.data.code == 401) {
                    
                    localStorage.removeItem('userData')

        
                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                  } else if (response.data.code == 503) {
                    this.pin = ''
                  } else  if( response.data.code === 404){
                         this.$eventBus.$emit('activeHome');
                         this.$eventBus.$emit('reiniciarAllNegocio');

                          }
                }
              }).catch(error => {
                this.$toast.error("Ocurrió un error inesperado", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              })
        
  
       
      },


          loadGeneral(){

            this.loading = true;         
              const userId_json = { userId: this.userId, idLocal: this.idLocal }
              const user_string = JSON.stringify(userId_json)

              const dataEncripted = this.$encryptBackoffice.encrypt(user_string);


      
  

              this.$https.post('/locals/checkIdentificacion/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
              
                if (response.data.code == 200) {
                
                  this.loading = false;
              

                  this.hasIdentificacion =response.data.hasIdentificacion;


                  if(this.hasIdentificacion){


                        this.identificacionLabel= response.data.identificacion;
                        this.displayNameLabel=response.data.displayName;


                        if(response.data.typeDocument === "DNI"){
                        this.typeDocumentLabel= "Cédula";


                        }else{

                          if(response.data.typeDocument === "RUC"){
                            this.typeDocumentLabel= "RUC";

                          }else{
                            this.typeDocumentLabel= "Identificación fiscal";

                          }

                        
                        }



                        }else{

                          this.isDni= true;
                      this.readonlyDisplayName=true;
                      this.identificacion="";
                      this.displayName="";
                      this.pin="";
                      this.typeDocument = "DNI"

                        }


           

                } else {


                  if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                    
                    localStorage.removeItem('userData')

                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                  } else {

                    if( response.data.code === 404){
                      this.$eventBus.$emit('activeHome');
                         this.$eventBus.$emit('reiniciarAllNegocio');
                    }else{
                      this.loadGeneral();
                    }

                  }
                }
              }).catch(error => {

                this.loadGeneral();
                
              })



          },
          isLetter(e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
      e.preventDefault() // If not match, don't add to input text
    },

    checkRUC(){


      
      if (this.identificacion == '') {

          } else if(Number(this.identificacion.length) < Number("13")){
            this.identificacion = '';

            this.$toast.error("El número RUC debe ser de 13 dígitos", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

          } else if (this.identificacion != this.rucRespaldo) {


            this.locales= [];
              this.nombreLocal="";
              this.direccionCompleta="";
              this.displayName="";



            this.isActiveProfile=true;
      

            document.getElementById('btn_general').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" /> Comprobando RUC, espere'

          const dataEncripted = this.$encryptBackoffice.encrypt(this.identificacion)


            this.$https.post('/profile/consultarRUC/', { tokenAuth: this.tokenAuth, ruc: dataEncripted }).then(response => {
              document.getElementById('btn_general').innerHTML = 'Actualizar datos'

            
              this.isActiveProfile=false;
          
              if (response.data.code == 200) {

            
                this.rucRespaldo = this.identificacion

                this.displayName = response.data.razonSocial;

                this.locales= response.data.locales;

                
              } else {

                this.rucRespaldo="";
                this.identificacion = ''

                this.$toast.error(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              }
            }).catch(error => {
              this.rucRespaldo="";
              this.identificacion = '';
              this.isActiveProfile=false;

              document.getElementById('btn_general').innerHTML = 'Actualizar datos'


              this.$toast.error("Error inesperado", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            })
          }

    },
          checkDNI() {
      if (this.identificacion == '') {

      } else if(Number(this.identificacion.length) < Number("10")){
            this.identificacion = '';

            this.$toast.error("El número de cédula debe ser de 10 dígitos", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

          } else if (this.identificacion != this.dniRespaldo) {

        this.isActiveProfile=true;


        document.getElementById('btn_general').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" /> Comprobando cédula, espere'

      const dataEncripted = this.$encryptBackoffice.encrypt(this.identificacion)


        this.$https.post('/profile/consultaDatos/', { cedula: dataEncripted }).then(response => {
          document.getElementById('btn_general').innerHTML = 'Actualizar datos'

       
          this.isActiveProfile=false;
      
          if (response.data.code == 200) {

        
            this.dniRespaldo = this.identificacion

            this.displayName = response.data.dataGeneral.nombres.trim()
     
            
          } else {
            this.identificacion = ''

            this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          }
        }).catch(error => {
          this.identificacion = '';
          this.isActiveProfile=false;

          document.getElementById('btn_general').innerHTML = 'Actualizar datos'


          this.$toast.error("Ocurrió un error inesperado", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        })
      }
    },

          NumbersOnly(evt) {
            evt = (evt) || window.event
            const charCode = (evt.which) ? evt.which : evt.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
              evt.preventDefault()
            } else {
              return true
            }
          },

          onChangeLocal(nombreLocal, direccionCompleta){

        
            this.nombreLocal=nombreLocal;
            this.direccionCompleta=direccionCompleta;
          },
            onChange(event) {

              this.typeDocument=event.target.value;

              this.locales= [];
              this.nombreLocal="";
              this.direccionCompleta="";

              this.identificacion="";
              this.pin="";
              this.displayName="";
              this.dniRespaldo="";
              this.rucRespaldo="";

            



                    if(this.typeDocument === "DNI"){
                    this.isDni= true;
                    this.isRuc= false;
                    this.isFiscal= false;
                    this.readonlyDisplayName=true;

                    }else{

                        if(this.typeDocument === "RUC"){
                            this.isRuc= true;
                            this.isDni= false;
                            this.isFiscal= false;
                            this.readonlyDisplayName=true;
                        }else{
                          this.isFiscal= true;
                          this.isDni= false;
                          this.isRuc= false;
                          this.readonlyDisplayName=false;
                        }

                       
                    }
                },
  
        
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      